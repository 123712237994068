import React, { Fragment } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

const ProductData = () => {
  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
            <Link to="/product-detail">
              <Card style={{ width: '26rem' }}>
                <Card.Img
                  variant="top"
                  src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
                />
                <Card.Body>
                  <Typography variant="h5" component="h2">
                    Edu Live className Link
                  </Typography>

                  <Typography variant="h6" component="h4">
                    Alpha
                  </Typography>
                  <Card.Text style={{ color: '#76777a' }}>
                    Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                    yang lebih personal dan privat
                  </Card.Text>
                  <hr />
                </Card.Body>
                <Row xs="2">
                  <Col>
                    <div
                      className="rating-product"
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <i
                        class="fa fa-star"
                        style={{ color: '#71C4E2', marginLeft: '10%' }}
                      ></i>
                      <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                      <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                      <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                      <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    </div>
                  </Col>
                  <Col>
                    <Row>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{
                          color: '#71C4E2',
                          fontWeight: 'bold',
                          marginBottom: '10px',
                        }}
                      >
                        Rp 600.000
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{
                          color: '#bebebe',
                          fontSize: 14,
                        }}
                      >
                        /bln
                      </Typography>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>

          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <br />

        <Row>
          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <br />

        <Row>
          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '26rem' }}>
              <Card.Img
                variant="top"
                src="https://qonstanta.com/images/produk-kami-edulive-alpha.svg"
              />
              <Card.Body>
                <Typography variant="h5" component="h2">
                  Edu Live className
                </Typography>

                <Typography variant="h6" component="h4">
                  Alpha
                </Typography>
                <Card.Text style={{ color: '#76777a' }}>
                  Bimbingan Belajar secara Live dengan menggunakan Apps Maota
                  yang lebih personal dan privat
                </Card.Text>
                <hr />
              </Card.Body>
              <Row xs="2">
                <Col>
                  <div
                    className="rating-product"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <i
                      class="fa fa-star"
                      style={{ color: '#71C4E2', marginLeft: '10%' }}
                    ></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                    <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        color: '#71C4E2',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Rp 600.000
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        color: '#bebebe',
                        fontSize: 14,
                      }}
                    >
                      /bln
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ProductData

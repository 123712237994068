import React, { Fragment, useState } from 'react'
import { Title } from './component/Title'
import { Tabs, Tab } from 'react-bootstrap'
import image from './img/img.png'
import Stepper from 'react-stepper-horizontal'

function ProductDetail() {
  const [key, setKey] = useState('home')
  return (
    <Fragment>
      <div class="container-fluid gedf-wrapper">
        <Title title={'Edu Live Class'} titleBlue={'ALPHA'} />
        <div class="row">
          <div class="col-md-8 gedf-main">
            <div class="card gedf-card">
              <div class="card-body">
                <div class="card-header" style={{ backgroundColor: '#F2F4F5' }}>
                  <div class="row">
                    <div class="col">
                      <h4>Kategori:</h4>
                      <p>ALPHA</p>
                    </div>
                    <div class="col">
                      {' '}
                      <h4>Review:</h4>
                      <p>
                        {' '}
                        <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                        <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                        <i class="fa fa-star" style={{ color: '#71C4E2' }}></i>
                        <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                        <i class="far fa-star" style={{ color: '#71C4E2' }}></i>
                      </p>
                    </div>
                    <div class="col">
                      {' '}
                      <h4>Nama Produk:</h4>
                      <p>Edu Live Class</p>
                    </div>
                  </div>
                </div>
                <img
                  src="http://www.qonstanta.com/images/produk-kami-edulive-alpha.svg"
                  alt="..."
                  class="img-thumbnail"
                ></img>
              </div>
            </div>

            {/* Here */}
            <div class="card gedf-card">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="home" title="Fasilitas">
                  <div class="border border-secondary" style={{ padding: 20 }}>
                    <h2>Fasilitas yang diberikan</h2>
                    <br />
                    <p style={{ fontWeight: 'bold' }}>
                      Pembelajaran secara Live
                    </p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rangkuman Pelajaran</b>
                        <b style={styles.textRed}> "Diberikan" </b>
                      </li>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Soal & Pembahasan</b>{' '}
                        <b style={styles.textRed}>"Diberikan"</b>
                      </li>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>
                          Post Test & Pembahasan
                        </b>{' '}
                        <b style={styles.textRed}>
                          "Diberikan Dengan Pembahasan Soal Terbatas"
                        </b>
                      </li>
                    </ul>

                    <p style={{ fontWeight: 'bold' }}>Bank Soal</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Soal & Kunci Jawabann</b>
                        <b style={styles.textRed}> "Diberikan" </b>
                      </li>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>
                          Post Test & Pembahasan{' '}
                        </b>{' '}
                        <b style={styles.textRed}>
                          "Diberikan Dengan Pembahasan Soal Terbatas"
                        </b>
                      </li>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Soal & Pembahasan</b>{' '}
                        <b style={styles.textRed}>
                          "Diberikan Dengan Pembahasan Soal Terbatas"
                        </b>
                      </li>
                    </ul>

                    <p style={{ fontWeight: 'bold' }}>Try Out Online Siswa</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Paket Try Out Online</b>
                        <b style={styles.textRed}>
                          {' '}
                          "Diberikan, maks. 8 kali per tahun"{' '}
                        </b>
                      </li>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>
                          Hasil dan Rasionalisasi{' '}
                        </b>{' '}
                        <b style={styles.textRed}>
                          "Diberikan dalam bentuk Pembahasan Tertulis"
                        </b>
                      </li>
                    </ul>

                    <p style={{ fontWeight: 'bold' }}>Laporan Evaluasi Siswa</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Evaluasi Periodik</b>
                        <b style={styles.textRed}> "Diberikan" </b>
                      </li>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>
                          Rekaman Aktivitas via Apps Maota
                        </b>{' '}
                        <b style={styles.textRed}>
                          "Diberikan Sesuai Permintaan"
                        </b>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Layanan">
                  <div class="border border-secondary" style={{ padding: 20 }}>
                    <h2>Fasilitas yang diberikan</h2>
                    <br />
                    <p style={{ fontWeight: 'bold' }}>Ruang Ngobrol</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>
                          Public Chat via Apps Maota
                        </b>
                        <b style={styles.textRed}>
                          {' '}
                          "Diberikan selama Break Live Class"{' '}
                        </b>
                      </li>
                    </ul>

                    <p style={{ fontWeight: 'bold' }}>Tips & Triks</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Tips & Triks</b>
                        <b style={styles.textRed}> "Diberikan" </b>
                      </li>
                    </ul>

                    <p style={{ fontWeight: 'bold' }}>Info Sekolah & PT</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Info Sekolah & PT</b>
                        <b style={styles.textRed}> "Diberikan" </b>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="setting" title="Info">
                  <div class="border border-secondary" style={{ padding: 20 }}>
                    <div className="row" style={{ paddingLeft: 15 }}>
                      <h2>Form Data Diri Siswa {` `}</h2>
                      <b
                        style={{
                          fontWeight: 'normal',
                          color: 'red',
                          marginTop: 5 + 'px',
                        }}
                      >
                        {' '}
                        (* Semua Text Wajib Di Isi)
                      </b>
                    </div>
                    <br />

                    <div>
                      <Stepper
                        steps={[
                          { title: 'Step One' },
                          { title: 'Step Two' },
                          { title: 'Step Three' },
                          { title: 'Step Four' },
                        ]}
                        activeStep={1}
                      />
                    </div>

                    <form>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Nama Lengkap</label>
                        <input
                          type="fullname"
                          class="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Nama Lengkap"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Nama Panggilan</label>
                        <input
                          type="name"
                          class="form-control"
                          placeholder="Nama Panggilan"
                        />
                      </div>

                      <div class="form-group ">
                        <label for="exampleInputEmail1">Jenis Kelamin</label>
                        <br />
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                          />
                          <label class="form-check-label" for="inlineRadio1">
                            Laki-Laki
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="option2"
                          />
                          <label class="form-check-label" for="inlineRadio2">
                            Perempuan
                          </label>
                        </div>
                        <br />
                        <br />
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Tanggal Lahir *
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            placeholder="10-11-2002"
                            name="date"
                            required
                          />
                        </div>

                        <div class="form-group">
                          <label for="exampleInputEmail1">Alamat Rumah</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Alamat Rumah"
                            name="date"
                          />
                        </div>

                        <div class="form-group">
                          <label for="exampleInputEmail1">Email *</label>
                          <input
                            type="email"
                            class="form-control"
                            placeholder="budi@gmail.com"
                            name="email"
                            required
                          />
                        </div>

                        <div class="form-group">
                          <label for="exampleInputEmail1">Nomor HP *</label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="083844164123"
                            name="date"
                            required
                          />
                        </div>

                        <div class="form-group">
                          <label for="exampleInputEmail1">Asal sekolah</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Asal Sekolah"
                            name="schools"
                            required
                          />
                        </div>
                      </div>

                      <button type="submit" class="btn btn-primary">
                        Submit
                      </button>
                    </form>
                  </div>
                </Tab>

                <Tab eventKey="paid" title="Biaya">
                  <div class="border border-secondary" style={{ padding: 20 }}>
                    <h2>Jenis Pembayaran</h2>
                    <p>Pembayaran Sekaligus 1 Tahun</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rp 8.000.000</b>
                      </li>
                    </ul>

                    <p>Cicilan 2 kali dalam Setahun (Per 6 Bulanan)</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rp 4.400.000</b>
                      </li>
                    </ul>

                    <p>Cicilan 3 kali dalam Setahun (Per 4 Bulanan)</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rp 2.940.000</b>
                      </li>
                    </ul>

                    <p>Cicilan 4 kali dalam Setahun (Per 3 Bulanan)</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rp 2.200.000</b>
                      </li>
                    </ul>

                    <p>Cicilan 6 kali dalam Setahun (Per 2 Bulanan)</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rp 1.470.000</b>
                      </li>
                    </ul>

                    <p>Cicilan 12 kali dalam Setahun (Per Bulan)</p>
                    <ul>
                      <li>
                        {' '}
                        <b style={styles.textBlue}>Rp 740.000</b>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <button type="button" class="btn btn-danger btn-lg btn-block">
              {' '}
              <span
                class="fa fa-shopping-cart"
                style={{ color: '#FFF' }}
              ></span>{' '}
              BELI SEKARANG
            </button>
          </div>

          {/* Here Tab */}

          <div class="col-md-4">
            <div class="card gedf-card">
              <div class="card-body">
                <h2 class="card-title">Fitur Kelas</h2>
                <h4 style={{ color: '#57B7DD', marginBottom: 1 + 'em' }}>
                  Rp 600.000 - Rp 6.500.000
                </h4>

                <div
                  class="row"
                  style={{ marginTop: 0.5 + 'em', marginBottom: 0.5 + 'em' }}
                >
                  <div class="col-sm-8">
                    {' '}
                    <span
                      class="fa fa-clock"
                      style={{ color: '#71C4E2' }}
                    ></span>{' '}
                    Durasi:
                  </div>
                  <div class="col-sm-4">90 menit</div>
                </div>

                <div
                  class="row"
                  style={{ marginTop: 0.5 + 'em', marginBottom: 0.5 + 'em' }}
                >
                  <div class="col-sm-8">
                    {' '}
                    <span
                      class="fa fa-file"
                      style={{ color: '#71C4E2' }}
                    ></span>{' '}
                    Try Out:
                  </div>
                  <div class="col-sm-4">Yes</div>
                </div>

                <div
                  class="row"
                  style={{ marginTop: 0.5 + 'em', marginBottom: 0.5 + 'em' }}
                >
                  <div class="col-sm-8">
                    {' '}
                    <span
                      class="far fa-question-circle"
                      style={{ color: '#71C4E2' }}
                    ></span>{' '}
                    Soal & Kunci Jawaban:
                  </div>
                  <div class="col-sm-4">Yes</div>
                </div>

                <div
                  class="row"
                  style={{ marginTop: 0.5 + 'em', marginBottom: 0.5 + 'em' }}
                >
                  <div class="col-sm-8">
                    {' '}
                    <span
                      class="fa fa-list-alt"
                      style={{ color: '#71C4E2' }}
                    ></span>{' '}
                    Evaluasi Periodik:
                  </div>
                  <div class="col-sm-4">Yes</div>
                </div>

                <div
                  class="row"
                  style={{ marginTop: 0.5 + 'em', marginBottom: 0.5 + 'em' }}
                >
                  <div class="col-sm-8">
                    {' '}
                    <span
                      class="fa fa-users"
                      style={{ color: '#71C4E2' }}
                    ></span>{' '}
                    Siswa:
                  </div>
                  <div class="col-sm-4">20</div>
                </div>

                <button type="button" class="btn btn-danger btn-lg btn-block">
                  {' '}
                  <span
                    class="fa fa-shopping-cart"
                    style={{ color: '#FFF' }}
                  ></span>{' '}
                  BELI SEKARANG
                </button>
              </div>
            </div>

            {/* Card */}

            <div class="card gedf-card">
              <div class="card-body">
                <img
                  src={image}
                  alt="..."
                  class="img-responsive"
                  width="300"
                  style={{ marginRight: 1 + 'em' }}
                ></img>
              </div>
            </div>
            {/* Card */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const styles = {
  textBlue: {
    color: '#57B7DD',
    fontWeight: 'normal',
  },
  textRed: {
    color: '#FE6B6B',
    fontWeight: 'normal',
  },
}

export default ProductDetail

import axios from "axios";

const _host = 'https://apiapps.qonstanta.com/auth';

export const LOGIN_URL = `${_host}/sign-in`;
export const REGISTER_URL = `${_host}/sign-up`;
export const REQUEST_PASSWORD_URL = `${_host}/forgot-password`;

export const CHANGE_PASSWORD = `${_host}/resetPassword`
export const FORGOT_PASSWORD = `${_host}/forgot-password`

export const PROFILE_URL = `${_host}/profile`

const headerConfig = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Access-Controls-Allow-Methods': '*'
}

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password }, {
    headers: headerConfig
  });
}

export function changePassword(password){
  return axios.get(`${CHANGE_PASSWORD}/${password}`)
}

export function forgotPassword(email) {
  return axios.post(FORGOT_PASSWORD, {email});
}

export function register({email, fullName, nickName, phone, birthPlace, birthDate, schoolOriginText, password}) {
  return axios.post(REGISTER_URL, { email, fullName, nickName, phone, birthDate, birthPlace, schoolOriginText, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(PROFILE_URL);
}

import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

export const Title = ({ title, titleBlue }) => {
  return (
    <Fragment>
      <div
        class="row-md-8"
        style={{
          backgroundColor: 'white',
          marginTop: 15 + 'px',
          padding: 2 + 'em',
        }}
      >
        <div className="row">
          <Typography
            variant="h4"
            component="h2"
            style={{ fontWeight: 'bold', marginLeft: 0.5 + 'em' }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            style={{ fontWeight: 'bold', color: '#57B7DD' }}
          >
            {` `} -{titleBlue}
          </Typography>
        </div>
      </div>
    </Fragment>
  )
}
